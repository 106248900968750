import type { KvKeys } from '@rialtic/types'
import { featureFlags, parseAppConfig } from '@rialtic/types'

export const useAppConfiguration = () => {
  const { $datadog } = useNuxtApp()

  const flags = useState('app-config', () => featureFlags)
  const getAllFlags = async () => {
    try {
      const keys = await $apiFetch<KvKeys[]>('/api/app-config')
      flags.value = parseAppConfig(keys)
    } catch (error) {
      $datadog.addError(error, {
        function: 'inquiries/appConfig:getAll',
      })
    }
  }
  return {
    getAllFlags,
    flags,
  }
}
